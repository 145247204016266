import React from 'react';
import { fluidImage } from '../../util/fluidImage';
import { graphql } from 'gatsby';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    Content,
    PageList,
    PagePreview,
    IntroQuote,
    Reference,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../components/InnerPage';

const GemeinsamesHandeln = ({ data }) => (
    <InnerPage
        title="Gemeinsames Handeln"
        description="Lebhafte Gemeinden kultivieren"
    >
        <IntroHolder
            title="Gemeinsames Handeln"
            subtitle="Lebhafte Gemeinden kultivieren"
            picture={data.introImage}
            breadcrumbs={{
                'current-item': 'Gemeinsames Handeln',
            }}
        >
            <IntroQuote>
                Bahá'u'lláh hat den Kreis der Einigkeit geschlagen. Er hat einen
                Plan geschaffen, um alle Völker zu vereinen und sie alle unter
                dem schützenden Zelt der allumfassenden Einheit zu versammeln.
                Dies ist das Werk der göttlichen Freigebigkeit, und wir alle
                müssen uns mit Herz und Seele mühen, bis wir die Einheit
                tatsächlich in unserer Mitte haben, und in dem Maß, in dem wir
                arbeiten, werden wir Kraft empfangen.
            </IntroQuote>
        </IntroHolder>
        <TwoColumns>
            <Content>
                <PageList>
                    <PagePreview
                        href="/gemeinsames-handeln/gemeinsames-lernen/"
                        title="Gemeinsames Lernen"
                        description="Teilnehmen und aktives Mitgestalten in eigener Umgebung"
                        imageSource={
                            data.gemeinsamesLernenFeature.childImageSharp.fluid
                                .src
                        }
                    />
                    <PagePreview
                        href="/gemeinsames-handeln/andacht-und-dienst/"
                        title="Andacht und Dienst"
                        description="Der Ausdruck des Wortes Gottes im Handeln"
                        imageSource={
                            data.andachtUndDienstFeature.childImageSharp.fluid
                                .src
                        }
                    />
                    <PagePreview
                        href="/gemeinsames-handeln/familie-und-kinder/"
                        title="Familie und Kinder"
                        description="Die Familie als kleinste Einheit einer Gesellschaft"
                        imageSource={
                            data.familieUndKinderFeature.childImageSharp.fluid
                                .src
                        }
                    />
                    <PagePreview
                        href="/gemeinsames-handeln/jugend/"
                        title="Jugend"
                        description="Junge Menschen zeigen Initiative und Verantwortung für ihr Umfeld"
                        imageSource={
                            data.jugendFeature.childImageSharp.fluid.src
                        }
                    />
                    <PagePreview
                        href="/gemeinsames-handeln/beteiligung-an-diskursen/"
                        title="Beteiligung an Diskursen"
                        description="Bemühung zum gesellschaftlichen Fortschritt beizutragen"
                        imageSource={
                            data.beteiligungAnDiskursenFeature.childImageSharp
                                .fluid.src
                        }
                    />
                    <PagePreview
                        href="/gemeinsames-handeln/die-bahai-in-deutschland/"
                        title="Die Bahá’í in Deutschland"
                        description="Beginn und Entwicklung der Bahá’í-Gemeinde in Deutschland"
                        imageSource={
                            data.dieBahaiGemeindeInDeutschlandFeature
                                .childImageSharp.fluid.src
                        }
                    />
                </PageList>
                <p>
                    <div className="video-wrapper">
                        <iframe
                            title="Aktivitäten der Baha'i Gemeinde in Deutschland"
                            src="https://www.youtube.com/embed/8_3337bz4ps?version=3&amp;rel=1&amp;fs=1&amp;autohide=2&amp;showsearch=0&amp;showinfo=1&amp;iv_load_policy=1&amp;wmode=transparent"
                            allowFullScreen={true}
                        />
                    </div>
                </p>
                <Reference>
                    <p>1. ‘Abdu’l-Bahá, Ansprachen in Paris (Kapitel 15)</p>
                </Reference>
            </Content>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default GemeinsamesHandeln;

export const pageQuery = graphql`
    query {
        introImage: file(
            relativePath: { eq: "gemeinsames-handeln-feature.jpg" }
        ) {
            ...fluidImage
        }
        beteiligungAnDiskursenFeature: file(
            relativePath: {
                eq: "beteiligung-an-gesellschaftlichen-diskursen-feature-1.jpg"
            }
        ) {
            ...pagePreviewImage
        }
        dieBahaiGemeindeInDeutschlandFeature: file(
            relativePath: { eq: "die-bahai-in-deutschland-feature.jpg" }
        ) {
            ...pagePreviewImage
        }
        jugendFeature: file(relativePath: { eq: "jugend-feature.jpg" }) {
            ...pagePreviewImage
        }
        familieUndKinderFeature: file(
            relativePath: { eq: "familie-und-kinder-feature.jpg" }
        ) {
            ...pagePreviewImage
        }
        andachtUndDienstFeature: file(
            relativePath: { eq: "andacht-und-dienst-img-feature.jpg" }
        ) {
            ...pagePreviewImage
        }
        gemeinsamesLernenFeature: file(
            relativePath: { eq: "gemeinsames-lernen-feature.jpg" }
        ) {
            ...pagePreviewImage
        }
    }
`;
